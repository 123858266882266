<template>
  <div class="progress_page">
    <div class="banner">
      <img src="@/assets/images/banzhuan/progress/banner.jpg" alt="" />
    </div>
    <div class="page_list" v-if="pageType === 'list'">
      <div class="content">
        <img
          src="@/assets/images/banzhuan/progress/progress1.png"
          alt=""
          class="progress1"
        />
        <div class="item">
          <img src="@/assets/images/banzhuan/progress/progress2.jpg" alt="" />
          <a class="btn" @click="onDetail('2019')">点击进入</a>
        </div>
        <img
          src="@/assets/images/banzhuan/progress/arrow.png"
          class="arrow"
          alt=""
        />
        <div class="item">
          <img src="@/assets/images/banzhuan/progress/progress3.jpg" alt="" />
          <a class="btn" @click="onDetail('2020')">点击进入</a>
        </div>
        <img
          src="@/assets/images/banzhuan/progress/arrow.png"
          class="arrow"
          alt=""
        />
        <div class="item">
          <img src="@/assets/images/banzhuan/progress/progress4.jpg" alt="" />
          <a class="btn" @click="onDetail('2021')">点击进入</a>
        </div>
      </div>
    </div>
    <div class="page_details" v-if="pageType === 'detail'">
      <div
        class="timeline"
        v-for="(item, index) in timeline"
        :key="index"
        v-if="year === item.year"
      >
        <div class="year_content">
          <div class="year">{{ item.year }}</div>
          <img src="@/assets/images/banzhuan/progress/progress3.jpg" alt="" />
          <div class="item" v-for="(el, i) in item.data" :key="i">
            <div class="flex" v-if="el.type === 'left'">
              <div class="item_left">
                <img :src="el.img" alt="" />
              </div>
              <div class="item_right">
                <div class="time">{{ el.time }}</div>
                <div class="title" v-html="el.title"></div>
                <div class="desc" v-html="el.desc"></div>
              </div>
            </div>
            <div class="flex" v-else>
              <div class="item_left">
                <div class="time">{{ el.time }}</div>
                <div class="title" v-html="el.title"></div>
                <div class="desc" v-html="el.desc"></div>
              </div>
              <div class="item_right">
                <img :src="el.img" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import timeline from './progressData'
import './progress.scss'
export default {
  name: 'Progress',
  components: {},
  data: function () {
    return {
      pageType: 'list',
      year: '',
      timeline: timeline,
    }
  },
  methods: {
    onDetail(data) {
      this.$router.push({
        path: `progress${data}`,
      })
    },
  },
}
</script>
<style scoped lang='scss'>
</style>
